import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "317a89d2-5a3e-470d-ac4f-44d32e0dd8df"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPages317a89d25a3e470dAc4f44d32e0dd8df(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPages317a89d25a3e470dAc4f44d32e0dd8df`}>
      {props.children}
    </div>
  );
}

// id: "a44380c3-2ee4-4322-88bf-30cf80753580"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefaultA44380c32ee4432288bf30cf80753580(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefaultA44380c32ee4432288bf30cf80753580 page`}>
      {props["header"] || <MembersAreaHeader36838c68F266418d953c738ec447ca65 parentTag="HeaderB762a06bC2414259A6293fa0b8d5b214" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b parentTag="Footer70c3280f180548cd839f8c5e48122d7c" {...props} />}
    </div>
  );
}

// id: "5083a633-2915-4d6d-860e-9f2cb86a1015"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogo5083a63329154d6d860e9f2cb86a1015(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.us-west-2.amazonaws.com/spp-logo.svg" className={`${parentTag || ""} DefaultLogo5083a63329154d6d860e9f2cb86a1015 logo`} alt="logo" />
  );
}

// id: "e9f874fa-52c8-4847-815e-9999e4e21b5d"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeaderE9f874fa52c84847815e9999e4e21b5d(props: any) {
  return (
    <ShortHeader24a29dadCfce40cdAe9bE8b48f7b0092 parentTag="DefaultHeaderE9f874fa52c84847815e9999e4e21b5d" title navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "31ccd827-1154-49d3-a514-374e7dbaf851"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPage31ccd827115449d3A514374e7dbaf851(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPage31ccd827115449d3A514374e7dbaf851 gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67 parentTag="Header05889efbD7fe4c7e8ff3B4edb83b2d01" title="Secondary Page" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b parentTag="Footer6a90d68aDbf94362915b0bcd809e37bb" {...props} />}
    </div>
  );
}

// id: "6f9bd9a0-8d8b-4838-b193-3ff68986687c"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuote6f9bd9a08d8b4838B1933ff68986687c(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuote6f9bd9a08d8b4838B1933ff68986687c`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "36838c68-f266-418d-953c-738ec447ca65"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeader36838c68F266418d953c738ec447ca65(props: any) {
  return (
    <ShortHeader24a29dadCfce40cdAe9bE8b48f7b0092 parentTag="MembersAreaHeader36838c68F266418d953c738ec447ca65" header-top={<DefaultHeaderTop6c5e0b8c3db74409B8fa4c5951206e8a parentTag="HeaderTop0151861c20d54b8c8446E272de4fb1b9" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "ed43810c-7d9e-4c53-9ba0-209f2e1f6903"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPageEd43810c7d9e4c539ba0209f2e1f6903(props: any) {
  return (
    <SecondaryPage31ccd827115449d3A514374e7dbaf851 parentTag="ResourcesPageEd43810c7d9e4c539ba0209f2e1f6903" header={<SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67 parentTag="Header84ebf4ff6d2d4b6a8bb1E86526d9491f" title="Resources" />} {...props} />
  );
}

// id: "d8ab2322-1b8c-40ad-b64e-8c4e5198f3d8"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBodyD8ab23221b8c40adB64e8c4e5198f3d8(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBodyD8ab23221b8c40adB64e8c4e5198f3d8 page-body`}>
      {props["marketingCards"] || <MarketingCards7f3ae3f06144437c8705D23d15300bd5 parentTag="MarketingCardsB696ecc1Cbc947fdAd2a9872a6c6b6b9" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "4b37a819-3904-459d-94c7-7defcb9b2e37"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label4b37a8193904459d94c77defcb9b2e37(props: any) {
  return (
    <DefaultLogo5083a63329154d6d860e9f2cb86a1015 parentTag="Label4b37a8193904459d94c77defcb9b2e37" {...props} />
  );
}

// id: "3bfa0d57-bc07-44b7-8190-3e8ac25be174"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPage3bfa0d57Bc0744b781903e8ac25be174(props: any) {
  return (
    <SecondaryPage31ccd827115449d3A514374e7dbaf851 parentTag="JoinUsPage3bfa0d57Bc0744b781903e8ac25be174" header={<SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67 parentTag="Header6c3092f4Fc6a46baBde81ebf4c1e7fcc" title="Join Us" />} {...props} />
  );
}

// id: "f0c2645b-1e57-4ae9-b42d-805d7a193ae6"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPagesF0c2645b1e574ae9B42d805d7a193ae6(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPagesF0c2645b1e574ae9B42d805d7a193ae6 gf-page-layout`}>
      {props["header"] || <MembersAreaHeader36838c68F266418d953c738ec447ca65 parentTag="HeaderD07f35454e484d08845a292523bbbf9a" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNav634c7ad684fb4146989952bd84791a35", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b parentTag="Footer348219cf75b14b17B2042e08bf5deaa0" {...props} />}
    </div>
  );
}

// id: "3f652b76-3c76-472e-9760-1f9ad404e2c8"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href3f652b763c76472e97601f9ad404e2c8 = "/";

// id: "2fd33afc-a5f8-4f0a-84a8-764657343f56"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero2fd33afcA5f84f0a84a8764657343f56(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero2fd33afcA5f84f0a84a8764657343f56 hero`}>
      <div className="title">
        {props["title"] || TitleEfda24ffE47f4b9a9f9d7c24590c0586}
      </div>
    </div>
  );
}

// id: "21d8d272-1995-4d4b-98dd-15fd08e628de"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeader21d8d27219954d4b98dd15fd08e628de(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeader21d8d27219954d4b98dd15fd08e628de site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop6c5e0b8c3db74409B8fa4c5951206e8a parentTag="HeaderTop958b1a3134fb4e26Aa88B79ee06cdad5" {...props} />}
      </div>
      {props["hero"] || <HomePageHeroEfae7cbd59e743fa98d50ec15847a267 parentTag="HeroD72ba79699e14e23B31801714ace5a80" {...props} />}
    </div>
  );
}

// id: "34fe2676-ffe1-4c74-9cc0-22f0ed1b5423"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function Footer34fe2676Ffe14c749cc022f0ed1b5423(props: any) {
  return (
    <DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b parentTag="Footer34fe2676Ffe14c749cc022f0ed1b5423" {...props} />
  );
}

// id: "36e4f28c-c9dc-4a1d-addb-96229cf8f272"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPages36e4f28cC9dc4a1dAddb96229cf8f272(props: any) {
  return (
    <SecondaryPage31ccd827115449d3A514374e7dbaf851 parentTag="SignupPages36e4f28cC9dc4a1dAddb96229cf8f272" header={<SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67 parentTag="Header5628ef5053a34380Bc4f33343354ad32" title="Join Us" />} {...props} />
  );
}

// id: "7836b94f-cb5d-4af2-b92a-ba79d6551435"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function Header7836b94fCb5d4af2B92aBa79d6551435(props: any) {
  return (
    <MembersAreaHeader36838c68F266418d953c738ec447ca65 parentTag="Header7836b94fCb5d4af2B92aBa79d6551435" {...props} />
  );
}

// id: "8c0374dd-1dab-4ee7-9942-019a93d9dc67"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop6c5e0b8c3db74409B8fa4c5951206e8a parentTag="HeaderTop0f9ab8c32dbe4b6fB91a5b79b9aba50e" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHero2fd33afcA5f84f0a84a8764657343f56 parentTag="HeroB981dfc36b4a4826B851B89a2834761b" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "634c7ad6-84fb-4146-9899-52bd84791a35"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "f0c2645b-1e57-4ae9-b42d-805d7a193ae6"
export function SecondaryNav634c7ad684fb4146989952bd84791a35(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNav634c7ad684fb4146989952bd84791a35", parentTag)} {...props} />
  );
}

// id: "6c3092f4-fc6a-46ba-bde8-1ebf4c1e7fcc"
// title: ""
// type: :reference
// key: "header"
// parent_id: "3bfa0d57-bc07-44b7-8190-3e8ac25be174"
export function Header6c3092f4Fc6a46baBde81ebf4c1e7fcc(props: any) {
  return (
    <SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67 parentTag="Header6c3092f4Fc6a46baBde81ebf4c1e7fcc" title="Join Us" {...props} />
  );
}

// id: "5b02a935-b3d1-4909-a984-3b08214e727c"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "24a29dad-cfce-40cd-ae9b-e8b48f7b0092"
export function LinkedLogo5b02a935B3d14909A9843b08214e727c(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo5b02a935B3d14909A9843b08214e727c", parentTag)} label={<DefaultLogo5083a63329154d6d860e9f2cb86a1015 parentTag="LabelE50c51e258f04098B7a793e17cb34a3b" alt="logo" />} {...props} />
  );
}

// id: "05889efb-d7fe-4c7e-8ff3-b4edb83b2d01"
// title: ""
// type: :reference
// key: "header"
// parent_id: "31ccd827-1154-49d3-a514-374e7dbaf851"
export function Header05889efbD7fe4c7e8ff3B4edb83b2d01(props: any) {
  return (
    <SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67 parentTag="Header05889efbD7fe4c7e8ff3B4edb83b2d01" title="Secondary Page" {...props} />
  );
}

// id: "f31e83de-900f-4515-ae5e-941412b2851b"
// title: ""
// type: :text
// key: "title"
// parent_id: "6c3092f4-fc6a-46ba-bde8-1ebf4c1e7fcc"
export const TitleF31e83de900f4515Ae5e941412b2851b = "Join Us";

// id: "90582f6f-5f10-41fb-abe6-d58c5f96da96"
// title: ""
// type: :text
// key: "url"
// parent_id: "0e85c298-ed86-4eb2-9005-d20b8ebf7e85"
export const Url90582f6f5f1041fbAbe6D58c5f96da96 = "/";

// id: "22f0fb66-4f35-4abb-a39e-ead966d7c93f"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePage22f0fb664f354abbA39eEad966d7c93f(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePage22f0fb664f354abbA39eEad966d7c93f page gf-page-layout page gf-page-layout`}>
      {props["header"] || <HomeHeader21d8d27219954d4b98dd15fd08e628de parentTag="Header829ee40947b64c40Bbed374a6ad7e367" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBackD7ff1605C63646d9B0866be12ff7651e", parentTag)} {...props} />}
      {props["body"] || <MarketingBodyD8ab23221b8c40adB64e8c4e5198f3d8 parentTag="Body53a75ced3c0843ee8ea5D6ee76dc456f" {...props} />}
      {props["quote"] || <PageQuote parentTag={buildClassName("Quote2a20a57fE09c4fc083e60136679cdb1c", parentTag)} contentSlug="home-page-quote" {...props} />}
      {props["footer"] || <DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b parentTag="Footer7a6ca4f2E30d4070B0f50c3073eaa8f3" {...props} />}
    </div>
  );
}

// id: "a25457c2-c89e-40e2-a375-7eebb10fa0a7"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "24a29dad-cfce-40cd-ae9b-e8b48f7b0092"
export const BackgroundImageA25457c2C89e40e2A3757eebb10fa0a7 = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "d72ba796-99e1-4e23-b318-01714ace5a80"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "21d8d272-1995-4d4b-98dd-15fd08e628de"
export function HeroD72ba79699e14e23B31801714ace5a80(props: any) {
  return (
    <HomePageHeroEfae7cbd59e743fa98d50ec15847a267 parentTag="HeroD72ba79699e14e23B31801714ace5a80" {...props} />
  );
}

// id: "efda24ff-e47f-4b9a-9f9d-7c24590c0586"
// title: ""
// type: :text
// key: "title"
// parent_id: "2fd33afc-a5f8-4f0a-84a8-764657343f56"
export const TitleEfda24ffE47f4b9a9f9d7c24590c0586 = "Default Title";

// id: "ccee19e8-c49c-4851-a2bc-5daeb6a0438c"
// title: ""
// type: :text
// key: "title"
// parent_id: "8c0374dd-1dab-4ee7-9942-019a93d9dc67"
export const TitleCcee19e8C49c4851A2bc5daeb6a0438c = "Secondary Marketing Title 1";

// id: "48fcdcce-ad8f-4b50-8b68-68fec9cdc998"
// title: ""
// type: :html
// key: "logo"
// parent_id: "f359745c-8d2f-4aa1-a680-e3a0177c0c8b"
export function Logo48fcdcceAd8f4b508b6868fec9cdc998(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/spp/images/spp-logo-white.svg" alt="SPP logo" className={`${parentTag || ""} Logo48fcdcceAd8f4b508b6868fec9cdc998 logo`} />
  );
}

// id: "b89d0495-b3c6-4621-ad3c-975e883d4629"
// title: ""
// type: :text
// key: "title"
// parent_id: "b981dfc3-6b4a-4826-b851-b89a2834761b"
export const TitleB89d0495B3c64621Ad3c975e883d4629 = "Secondary Marketing Title 2";

// id: "2445cd19-c478-4cb8-97de-e20f42c97789"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "a4b291dd-ee1a-4023-bfa7-4753ffd7623a"
export const PostSlug2445cd19C4784cb897deE20f42c97789 = "about-us-posts";

// id: "2ccd4777-5f6f-428a-aeef-b6e6d894cf00"
// title: ""
// type: :reference
// key: "header"
// parent_id: "0eb43bbd-0201-4b95-809a-bd20568215a2"
export function Header2ccd47775f6f428aAeefB6e6d894cf00(props: any) {
  return (
    <SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67 parentTag="Header2ccd47775f6f428aAeefB6e6d894cf00" title="About Us" {...props} />
  );
}

// id: "a4b291dd-ee1a-4023-bfa7-4753ffd7623a"
// title: ""
// type: :reference
// key: "body"
// parent_id: "0eb43bbd-0201-4b95-809a-bd20568215a2"
export function BodyA4b291ddEe1a4023Bfa74753ffd7623a(props: any) {
  return (
    <MarketingBodyD8ab23221b8c40adB64e8c4e5198f3d8 parentTag="BodyA4b291ddEe1a4023Bfa74753ffd7623a" postSlug="about-us-posts" {...props} />
  );
}

// id: "b762a06b-c241-4259-a629-3fa0b8d5b214"
// title: ""
// type: :reference
// key: "header"
// parent_id: "a44380c3-2ee4-4322-88bf-30cf80753580"
export function HeaderB762a06bC2414259A6293fa0b8d5b214(props: any) {
  return (
    <MembersAreaHeader36838c68F266418d953c738ec447ca65 parentTag="HeaderB762a06bC2414259A6293fa0b8d5b214" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "baff33a8-3bc7-4d6d-a3a4-e0d363d8c8fb"
// title: ""
// type: :reference
// key: "header"
// parent_id: "ce93bc80-e9a1-4520-858e-624d1b3c58e5"
export function HeaderBaff33a83bc74d6dA3a4E0d363d8c8fb(props: any) {
  return (
    <SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67 parentTag="HeaderBaff33a83bc74d6dA3a4E0d363d8c8fb" title="Public Events" {...props} />
  );
}

// id: "53a75ced-3c08-43ee-8ea5-d6ee76dc456f"
// title: ""
// type: :reference
// key: "body"
// parent_id: "22f0fb66-4f35-4abb-a39e-ead966d7c93f"
export function Body53a75ced3c0843ee8ea5D6ee76dc456f(props: any) {
  return (
    <MarketingBodyD8ab23221b8c40adB64e8c4e5198f3d8 parentTag="Body53a75ced3c0843ee8ea5D6ee76dc456f" {...props} />
  );
}

// id: "9af01396-bfc0-47d9-ad00-ea136aa55c56"
// title: ""
// type: :text
// key: "class"
// parent_id: "a09e4bfb-14f5-4405-a5b6-b92e1e0a7561"
export const Class9af01396Bfc047d9Ad00Ea136aa55c56 = "navigation";

// id: "b236bdca-c6dc-4a7e-b4b8-0112a9cc6039"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "0151861c-20d5-4b8c-8446-e272de4fb1b9"
export const NavMenuSlugB236bdcaC6dc4a7eB4b80112a9cc6039 = "members-primary-nav";

// id: "ce93bc80-e9a1-4520-858e-624d1b3c58e5"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPageCe93bc80E9a14520858e624d1b3c58e5(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPageCe93bc80E9a14520858e624d1b3c58e5 gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67 parentTag="HeaderBaff33a83bc74d6dA3a4E0d363d8c8fb" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b parentTag="FooterB8592a16De384bca91a1Bee6dc9b97bf" {...props} />}
    </div>
  );
}

// id: "a09e4bfb-14f5-4405-a5b6-b92e1e0a7561"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "6c5e0b8c-3db7-4409-b8fa-4c5951206e8a"
export function NavigationA09e4bfb14f54405A5b6B92e1e0a7561(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("NavigationA09e4bfb14f54405A5b6B92e1e0a7561", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "ff8e0e8c-273f-4b56-a111-a1ae103bc853"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "f359745c-8d2f-4aa1-a680-e3a0177c0c8b"
export function GroupflowFf8e0e8c273f4b56A111A1ae103bc853(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("GroupflowFf8e0e8c273f4b56A111A1ae103bc853", parentTag)} {...props} />
  );
}

// id: "c093b8b6-6fb7-4463-b27b-700672bc8d98"
// title: ""
// type: :text
// key: "alt"
// parent_id: "5083a633-2915-4d6d-860e-9f2cb86a1015"
export const AltC093b8b66fb74463B27b700672bc8d98 = "logo";

// id: "fabf11b0-4903-4005-8c44-42b21f976149"
// title: ""
// type: :text
// key: "title"
// parent_id: "e9f874fa-52c8-4847-815e-9999e4e21b5d"
export const TitleFabf11b0490340058c4442b21f976149 = null;

// id: "b981dfc3-6b4a-4826-b851-b89a2834761b"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "8c0374dd-1dab-4ee7-9942-019a93d9dc67"
export function HeroB981dfc36b4a4826B851B89a2834761b(props: any) {
  return (
    <SecondaryMarketingHero2fd33afcA5f84f0a84a8764657343f56 parentTag="HeroB981dfc36b4a4826B851B89a2834761b" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "509d6dde-e0da-4f0c-8be6-40b269b4aba4"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "2a20a57f-e09c-4fc0-83e6-0136679cdb1c"
export const ContentSlug509d6ddeE0da4f0c8be640b269b4aba4 = "home-page-quote";

// id: "455232c9-a112-4a43-878f-781d4cb10b3c"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "2c4a6110-77fa-4255-8687-a7066defc8c7"
export const NavMenuSlug455232c9A1124a43878f781d4cb10b3c = "members-primary-nav";

// id: "f359745c-8d2f-4aa1-a680-e3a0177c0c8b"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b footer`}>
      {props["social-links"] || <SocialLinks6b7b1bf7Eca347b0A59c7bee9bbbb33c />}
      {props["links"] || <Navigation parentTag={buildClassName("LinksAcaad22e76854e61883cC8c564d79fc1", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <Logo48fcdcceAd8f4b508b6868fec9cdc998 />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("Copyright0526683e548c4a1dA21121874d4a5c33", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("GroupflowFf8e0e8c273f4b56A111A1ae103bc853", parentTag)} {...props} />}
    </div>
  );
}

// id: "0151861c-20d5-4b8c-8446-e272de4fb1b9"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "36838c68-f266-418d-953c-738ec447ca65"
export function HeaderTop0151861c20d54b8c8446E272de4fb1b9(props: any) {
  return (
    <DefaultHeaderTop6c5e0b8c3db74409B8fa4c5951206e8a parentTag="HeaderTop0151861c20d54b8c8446E272de4fb1b9" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "0526683e-548c-4a1d-a211-21874d4a5c33"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "f359745c-8d2f-4aa1-a680-e3a0177c0c8b"
export function Copyright0526683e548c4a1dA21121874d4a5c33(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright0526683e548c4a1dA21121874d4a5c33", parentTag)} {...props} />
  );
}

// id: "7a6ca4f2-e30d-4070-b0f5-0c3073eaa8f3"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "22f0fb66-4f35-4abb-a39e-ead966d7c93f"
export function Footer7a6ca4f2E30d4070B0f50c3073eaa8f3(props: any) {
  return (
    <DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b parentTag="Footer7a6ca4f2E30d4070B0f50c3073eaa8f3" {...props} />
  );
}

// id: "b8592a16-de38-4bca-91a1-bee6dc9b97bf"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "ce93bc80-e9a1-4520-858e-624d1b3c58e5"
export function FooterB8592a16De384bca91a1Bee6dc9b97bf(props: any) {
  return (
    <DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b parentTag="FooterB8592a16De384bca91a1Bee6dc9b97bf" {...props} />
  );
}

// id: "e4570dd0-5a92-459f-90db-c120f3421846"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "b696ecc1-cbc9-47fd-ad2a-9872a6c6b6b9"
export const PostSlugE4570dd05a92459f90dbC120f3421846 = "home-page-posts2";

// id: "5cec5962-cefb-4254-b002-f215681c8f98"
// title: ""
// type: :text
// key: "class"
// parent_id: "0e85c298-ed86-4eb2-9005-d20b8ebf7e85"
export const Class5cec5962Cefb4254B002F215681c8f98 = "linked-logo";

// id: "e50c51e2-58f0-4098-b7a7-93e17cb34a3b"
// title: ""
// type: :reference
// key: "label"
// parent_id: "5b02a935-b3d1-4909-a984-3b08214e727c"
export function LabelE50c51e258f04098B7a793e17cb34a3b(props: any) {
  return (
    <DefaultLogo5083a63329154d6d860e9f2cb86a1015 parentTag="LabelE50c51e258f04098B7a793e17cb34a3b" {...props} />
  );
}

// id: "24a29dad-cfce-40cd-ae9b-e8b48f7b0092"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeader24a29dadCfce40cdAe9bE8b48f7b0092(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeader24a29dadCfce40cdAe9bE8b48f7b0092 site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo5b02a935B3d14909A9843b08214e727c", parentTag)} label={<DefaultLogo5083a63329154d6d860e9f2cb86a1015 parentTag="LabelE50c51e258f04098B7a793e17cb34a3b" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation2c4a611077fa42558687A7066defc8c7", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "cd472c1b-6d34-4efa-8a75-8850b4c01f0d"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "5083a633-2915-4d6d-860e-9f2cb86a1015"
export const ImageUrlCd472c1b6d344efa8a758850b4c01f0d = "https://mp1md-pub.s3.us-west-2.amazonaws.com/spp-logo.svg";

// id: "2c4a6110-77fa-4255-8687-a7066defc8c7"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "24a29dad-cfce-40cd-ae9b-e8b48f7b0092"
export function Navigation2c4a611077fa42558687A7066defc8c7(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation2c4a611077fa42558687A7066defc8c7", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "1a655bba-2669-48a5-aae6-514d748ed672"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "7f3ae3f0-6144-437c-8705-d23d15300bd5"
export const ButtonClass1a655bba266948a5Aae6514d748ed672 = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "829ee409-47b6-4c40-bbed-374a6ad7e367"
// title: ""
// type: :reference
// key: "header"
// parent_id: "22f0fb66-4f35-4abb-a39e-ead966d7c93f"
export function Header829ee40947b64c40Bbed374a6ad7e367(props: any) {
  return (
    <HomeHeader21d8d27219954d4b98dd15fd08e628de parentTag="Header829ee40947b64c40Bbed374a6ad7e367" {...props} />
  );
}

// id: "ba6645da-f107-4861-ba30-a5e91847de13"
// title: ""
// type: :text
// key: "title"
// parent_id: "05889efb-d7fe-4c7e-8ff3-b4edb83b2d01"
export const TitleBa6645daF1074861Ba30A5e91847de13 = "Secondary Page";

// id: "6067995b-192d-46ee-9e8e-10d72dd9efbc"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "a09e4bfb-14f5-4405-a5b6-b92e1e0a7561"
export const NavMenuSlug6067995b192d46ee9e8e10d72dd9efbc = "marketing-primary-nav";

// id: "a10ce2f7-77a2-4ba4-9005-c5de9566c279"
// title: ""
// type: :text
// key: "title"
// parent_id: "baff33a8-3bc7-4d6d-a3a4-e0d363d8c8fb"
export const TitleA10ce2f777a24ba49005C5de9566c279 = "Public Events";

// id: "3b3393f1-8f30-4d4d-823a-ca7c88d92eb3"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "0eb43bbd-0201-4b95-809a-bd20568215a2"
export function Footer3b3393f18f304d4d823aCa7c88d92eb3(props: any) {
  return (
    <DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b parentTag="Footer3b3393f18f304d4d823aCa7c88d92eb3" {...props} />
  );
}

// id: "348219cf-75b1-4b17-b204-2e08bf5deaa0"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "f0c2645b-1e57-4ae9-b42d-805d7a193ae6"
export function Footer348219cf75b14b17B2042e08bf5deaa0(props: any) {
  return (
    <DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b parentTag="Footer348219cf75b14b17B2042e08bf5deaa0" {...props} />
  );
}

// id: "1eef829c-bb1a-411c-a9f5-edd7f48da06c"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "efae7cbd-59e7-43fa-98d5-0ec15847a267"
export function Button1eef829cBb1a411cA9f5Edd7f48da06c(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("Button1eef829cBb1a411cA9f5Edd7f48da06c", parentTag)} label="Join Us" href="/join" {...props} />
  );
}

// id: "f55a3293-0d8e-40a4-ae9e-f5e1512ecbfc"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "acaad22e-7685-4e61-883c-c8c564d79fc1"
export const NavMenuSlugF55a32930d8e40a4Ae9eF5e1512ecbfc = "footer-nav";

// id: "5ad6d131-a580-4ddc-ae2f-b53a46ebaf81"
// title: ""
// type: :text
// key: "label"
// parent_id: "1eef829c-bb1a-411c-a9f5-edd7f48da06c"
export const Label5ad6d131A5804ddcAe2fB53a46ebaf81 = "Join Us";

// id: "0e85c298-ed86-4eb2-9005-d20b8ebf7e85"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "6c5e0b8c-3db7-4409-b8fa-4c5951206e8a"
export function LinkedLogo0e85c298Ed864eb29005D20b8ebf7e85(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo0e85c298Ed864eb29005D20b8ebf7e85", parentTag)} url="/" className="linked-logo" label={<DefaultLogo5083a63329154d6d860e9f2cb86a1015 parentTag="LabelE07775e12d3249e9A19222fdadcba5e7" alt="logo" />} {...props} />
  );
}

// id: "958b1a31-34fb-4e26-aa88-b79ee06cdad5"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "21d8d272-1995-4d4b-98dd-15fd08e628de"
export function HeaderTop958b1a3134fb4e26Aa88B79ee06cdad5(props: any) {
  return (
    <DefaultHeaderTop6c5e0b8c3db74409B8fa4c5951206e8a parentTag="HeaderTop958b1a3134fb4e26Aa88B79ee06cdad5" {...props} />
  );
}

// id: "5628ef50-53a3-4380-bc4f-33343354ad32"
// title: ""
// type: :reference
// key: "header"
// parent_id: "36e4f28c-c9dc-4a1d-addb-96229cf8f272"
export function Header5628ef5053a34380Bc4f33343354ad32(props: any) {
  return (
    <SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67 parentTag="Header5628ef5053a34380Bc4f33343354ad32" title="Join Us" {...props} />
  );
}

// id: "acaad22e-7685-4e61-883c-c8c564d79fc1"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "f359745c-8d2f-4aa1-a680-e3a0177c0c8b"
export function LinksAcaad22e76854e61883cC8c564d79fc1(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("LinksAcaad22e76854e61883cC8c564d79fc1", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "b696ecc1-cbc9-47fd-ad2a-9872a6c6b6b9"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "d8ab2322-1b8c-40ad-b64e-8c4e5198f3d8"
export function MarketingCardsB696ecc1Cbc947fdAd2a9872a6c6b6b9(props: any) {
  return (
    <MarketingCards7f3ae3f06144437c8705D23d15300bd5 parentTag="MarketingCardsB696ecc1Cbc947fdAd2a9872a6c6b6b9" postSlug="home-page-posts2" {...props} />
  );
}

// id: "e07775e1-2d32-49e9-a192-22fdadcba5e7"
// title: ""
// type: :reference
// key: "label"
// parent_id: "0e85c298-ed86-4eb2-9005-d20b8ebf7e85"
export function LabelE07775e12d3249e9A19222fdadcba5e7(props: any) {
  return (
    <DefaultLogo5083a63329154d6d860e9f2cb86a1015 parentTag="LabelE07775e12d3249e9A19222fdadcba5e7" {...props} />
  );
}

// id: "659e3d80-61ec-4bc3-a9d0-e72fc5faaae5"
// title: ""
// type: :text
// key: "title"
// parent_id: "2ccd4777-5f6f-428a-aeef-b6e6d894cf00"
export const Title659e3d8061ec4bc3A9d0E72fc5faaae5 = "About Us";

// id: "1c0a0b05-3b0d-4c32-96f9-cc157af039f7"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "7f3ae3f0-6144-437c-8705-d23d15300bd5"
export const PostSlug1c0a0b053b0d4c3296f9Cc157af039f7 = "home-page-posts2";

// id: "84ebf4ff-6d2d-4b6a-8bb1-e86526d9491f"
// title: ""
// type: :reference
// key: "header"
// parent_id: "ed43810c-7d9e-4c53-9ba0-209f2e1f6903"
export function Header84ebf4ff6d2d4b6a8bb1E86526d9491f(props: any) {
  return (
    <SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67 parentTag="Header84ebf4ff6d2d4b6a8bb1E86526d9491f" title="Resources" {...props} />
  );
}

// id: "ceeb3edd-c3aa-4cea-be28-cc29bf5084c6"
// title: ""
// type: :reference
// key: "header"
// parent_id: "0cc80d9b-091e-4738-a0d0-b00722ae56e8"
export function HeaderCeeb3eddC3aa4ceaBe28Cc29bf5084c6(props: any) {
  return (
    <MembersAreaHeader36838c68F266418d953c738ec447ca65 parentTag="HeaderCeeb3eddC3aa4ceaBe28Cc29bf5084c6" {...props} />
  );
}

// id: "70c3280f-1805-48cd-839f-8c5e48122d7c"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "a44380c3-2ee4-4322-88bf-30cf80753580"
export function Footer70c3280f180548cd839f8c5e48122d7c(props: any) {
  return (
    <DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b parentTag="Footer70c3280f180548cd839f8c5e48122d7c" {...props} />
  );
}

// id: "0f9ab8c3-2dbe-4b6f-b91a-5b79b9aba50e"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "8c0374dd-1dab-4ee7-9942-019a93d9dc67"
export function HeaderTop0f9ab8c32dbe4b6fB91a5b79b9aba50e(props: any) {
  return (
    <DefaultHeaderTop6c5e0b8c3db74409B8fa4c5951206e8a parentTag="HeaderTop0f9ab8c32dbe4b6fB91a5b79b9aba50e" {...props} />
  );
}

// id: "2538bda9-41a4-4f5e-906c-23d45a5f83f5"
// title: ""
// type: :text
// key: "title"
// parent_id: "84ebf4ff-6d2d-4b6a-8bb1-e86526d9491f"
export const Title2538bda941a44f5e906c23d45a5f83f5 = "Resources";

// id: "715c8f05-94cf-4018-9e55-f432e998a93e"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "b762a06b-c241-4259-a629-3fa0b8d5b214"
export const NavMenuSlug715c8f0594cf40189e55F432e998a93e = "members-primary-nav";

// id: "2a20a57f-e09c-4fc0-83e6-0136679cdb1c"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "22f0fb66-4f35-4abb-a39e-ead966d7c93f"
export function Quote2a20a57fE09c4fc083e60136679cdb1c(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("Quote2a20a57fE09c4fc083e60136679cdb1c", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "d07f3545-4e48-4d08-845a-292523bbbf9a"
// title: ""
// type: :reference
// key: "header"
// parent_id: "f0c2645b-1e57-4ae9-b42d-805d7a193ae6"
export function HeaderD07f35454e484d08845a292523bbbf9a(props: any) {
  return (
    <MembersAreaHeader36838c68F266418d953c738ec447ca65 parentTag="HeaderD07f35454e484d08845a292523bbbf9a" {...props} />
  );
}

// id: "6a90d68a-dbf9-4362-915b-0bcd809e37bb"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "31ccd827-1154-49d3-a514-374e7dbaf851"
export function Footer6a90d68aDbf94362915b0bcd809e37bb(props: any) {
  return (
    <DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b parentTag="Footer6a90d68aDbf94362915b0bcd809e37bb" {...props} />
  );
}

// id: "d7ff1605-c636-46d9-b086-6be12ff7651e"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "22f0fb66-4f35-4abb-a39e-ead966d7c93f"
export function WelcomeBackD7ff1605C63646d9B0866be12ff7651e(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBackD7ff1605C63646d9B0866be12ff7651e", parentTag)} {...props} />
  );
}

// id: "efdf42b3-19d5-4efb-9f75-e807ea912dbe"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "21d8d272-1995-4d4b-98dd-15fd08e628de"
export const BackgroundImageEfdf42b319d54efb9f75E807ea912dbe = "https://mp1md-pub.s3.amazonaws.com/spp/images/page-background-photo.jpg";

// id: "5b4d6fce-ed06-43b8-bba6-b0f1bbf4fe25"
// title: ""
// type: :text
// key: "href"
// parent_id: "1eef829c-bb1a-411c-a9f5-edd7f48da06c"
export const Href5b4d6fceEd0643b8Bba6B0f1bbf4fe25 = "/join";

// id: "8dfbb345-24a2-49e2-b1fe-dbf08a0be707"
// title: ""
// type: :text
// key: "title"
// parent_id: "5628ef50-53a3-4380-bc4f-33343354ad32"
export const Title8dfbb34524a249e2B1feDbf08a0be707 = "Join Us";

// id: "f4d7f00f-aa14-46a0-befd-56fd916b7809"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPageF4d7f00fAa1446a0Befd56fd916b7809(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPageF4d7f00fAa1446a0Befd56fd916b7809 gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67 parentTag="HeaderBbeb56eb9e5e4d1590228e47e94d2f1d" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b parentTag="Footer3529ab85326f480196758e39d125507d" {...props} />}
    </div>
  );
}

// id: "bbeb56eb-9e5e-4d15-9022-8e47e94d2f1d"
// title: ""
// type: :reference
// key: "header"
// parent_id: "f4d7f00f-aa14-46a0-befd-56fd916b7809"
export function HeaderBbeb56eb9e5e4d1590228e47e94d2f1d(props: any) {
  return (
    <SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67 parentTag="HeaderBbeb56eb9e5e4d1590228e47e94d2f1d" title="Leveling" {...props} />
  );
}

// id: "5c2c0732-dec1-447b-9642-287707a95f48"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const Title5c2c0732Dec1447b9642287707a95f48 = "Leveling";

// id: "cab337d6-6cfa-48c8-80d8-6f53861213d1"
// title: ""
// type: :text
// key: "title"
// parent_id: "bbeb56eb-9e5e-4d15-9022-8e47e94d2f1d"
export const TitleCab337d66cfa48c880d86f53861213d1 = "Leveling";

// id: "3529ab85-326f-4801-9675-8e39d125507d"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "f4d7f00f-aa14-46a0-befd-56fd916b7809"
export function Footer3529ab85326f480196758e39d125507d(props: any) {
  return (
    <DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b parentTag="Footer3529ab85326f480196758e39d125507d" {...props} />
  );
}

// id: "0cc80d9b-091e-4738-a0d0-b00722ae56e8"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages0cc80d9b091e4738A0d0B00722ae56e8(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages0cc80d9b091e4738A0d0B00722ae56e8 gf-page-layout`}>
      {props["header"] || <MembersAreaHeader36838c68F266418d953c738ec447ca65 parentTag="HeaderCeeb3eddC3aa4ceaBe28Cc29bf5084c6" {...props} />}
      {props.children}
    </div>
  );
}

// id: "853e9e3b-cde6-4728-849f-a7df7e65f501"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "e9f874fa-52c8-4847-815e-9999e4e21b5d"
export const NavMenuSlug853e9e3bCde64728849fA7df7e65f501 = "marketing-primary-nav";

// id: "4a7bde77-a73a-4f10-8cbc-d18b3094fbf8"
// title: "Polytopia 2024"
// type: :html
// key: "polytopia-2024"
// parent_id: nil
export function Polytopia20244a7bde77A73a4f108cbcD18b3094fbf8(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} Polytopia20244a7bde77A73a4f108cbcD18b3094fbf8 page gf-page-layout page gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67 parentTag="HeaderE58ea654408944e996cc2228ee808116" title="Polytopia 2024" {...props} />}
      <main>
        {props["body"] || <MarketingCards7f3ae3f06144437c8705D23d15300bd5 parentTag="Body229a9882E262432dB023A01a5d623fac" postSlug="polytopia-2024" {...props} />}
      </main>
      {props["footer"] || <DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b parentTag="FooterDafab59dFf35463bB04eD4dc62ae226c" {...props} />}
    </div>
  );
}

// id: "dafab59d-ff35-463b-b04e-d4dc62ae226c"
// title: "Footer"
// type: :reference
// key: "footer"
// parent_id: "4a7bde77-a73a-4f10-8cbc-d18b3094fbf8"
export function FooterDafab59dFf35463bB04eD4dc62ae226c(props: any) {
  return (
    <DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b parentTag="FooterDafab59dFf35463bB04eD4dc62ae226c" {...props} />
  );
}

// id: "7ac594c4-826d-499a-b701-fc52ffc9a125"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "229a9882-e262-432d-b023-a01a5d623fac"
export const PostSlug7ac594c4826d499aB701Fc52ffc9a125 = "polytopia-2024";

// id: "e58ea654-4089-44e9-96cc-2228ee808116"
// title: "Header"
// type: :reference
// key: "header"
// parent_id: "4a7bde77-a73a-4f10-8cbc-d18b3094fbf8"
export function HeaderE58ea654408944e996cc2228ee808116(props: any) {
  return (
    <SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67 parentTag="HeaderE58ea654408944e996cc2228ee808116" title="Polytopia 2024" {...props} />
  );
}

// id: "dd04e8eb-9d26-47f7-9ebc-882cd6f996d2"
// title: ""
// type: :text
// key: "title"
// parent_id: "e58ea654-4089-44e9-96cc-2228ee808116"
export const TitleDd04e8eb9d2647f79ebc882cd6f996d2 = "Polytopia 2024";

// id: "229a9882-e262-432d-b023-a01a5d623fac"
// title: ""
// type: :reference
// key: "body"
// parent_id: "4a7bde77-a73a-4f10-8cbc-d18b3094fbf8"
export function Body229a9882E262432dB023A01a5d623fac(props: any) {
  return (
    <MarketingCards7f3ae3f06144437c8705D23d15300bd5 parentTag="Body229a9882E262432dB023A01a5d623fac" postSlug="polytopia-2024" {...props} />
  );
}

// id: "0eb43bbd-0201-4b95-809a-bd20568215a2"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage0eb43bbd02014b95809aBd20568215a2(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage0eb43bbd02014b95809aBd20568215a2 page gf-page-layout page gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67 parentTag="Header2ccd47775f6f428aAeefB6e6d894cf00" title="About Us" {...props} />}
      {props["body"] || <MarketingBodyD8ab23221b8c40adB64e8c4e5198f3d8 parentTag="BodyA4b291ddEe1a4023Bfa74753ffd7623a" postSlug="about-us-posts" {...props} />}
      {props["footer"] || <DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b parentTag="Footer3b3393f18f304d4d823aCa7c88d92eb3" {...props} />}
    </div>
  );
}

// id: "6c5e0b8c-3db7-4409-b8fa-4c5951206e8a"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTop6c5e0b8c3db74409B8fa4c5951206e8a(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTop6c5e0b8c3db74409B8fa4c5951206e8a header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo0e85c298Ed864eb29005D20b8ebf7e85", parentTag)} url="/" className="linked-logo" label={<DefaultLogo5083a63329154d6d860e9f2cb86a1015 parentTag="LabelE07775e12d3249e9A19222fdadcba5e7" alt="logo" />} {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("NavigationA09e4bfb14f54405A5b6B92e1e0a7561", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />}
    </div>
  );
}

// id: "601f4a4d-a984-4ab7-baed-af421240c568"
// title: "Polytopia 2025"
// type: :html
// key: "polytopia-2025"
// parent_id: nil
export function Polytopia2025601f4a4dA9844ab7BaedAf421240c568(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} Polytopia2025601f4a4dA9844ab7BaedAf421240c568 page gf-page-layout page gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67 parentTag="Header0e60b66b16544647895d34c37cc35c2b" title="Polytopia 2025" {...props} />}
      <main>
        {props["body"] || <MarketingCards7f3ae3f06144437c8705D23d15300bd5 parentTag="Body274169a52d764af5B001151e163147a1" postSlug="polytopia-2025" {...props} />}
      </main>
      {props["footer"] || <DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b parentTag="Footer9bf292dfDbb6468a86ffE8e297476192" {...props} />}
    </div>
  );
}

// id: "9bf292df-dbb6-468a-86ff-e8e297476192"
// title: "Footer Clone xRXtkVcbB7vf9dR"
// type: :reference
// key: "footer"
// parent_id: "601f4a4d-a984-4ab7-baed-af421240c568"
export function Footer9bf292dfDbb6468a86ffE8e297476192(props: any) {
  return (
    <DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b parentTag="Footer9bf292dfDbb6468a86ffE8e297476192" {...props} />
  );
}

// id: "0e60b66b-1654-4647-895d-34c37cc35c2b"
// title: "Header Clone SiJc8owDIOQgqYh"
// type: :reference
// key: "header"
// parent_id: "601f4a4d-a984-4ab7-baed-af421240c568"
export function Header0e60b66b16544647895d34c37cc35c2b(props: any) {
  return (
    <SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67 parentTag="Header0e60b66b16544647895d34c37cc35c2b" title="Polytopia 2025" {...props} />
  );
}

// id: "274169a5-2d76-4af5-b001-151e163147a1"
// title: ""
// type: :reference
// key: "body"
// parent_id: "601f4a4d-a984-4ab7-baed-af421240c568"
export function Body274169a52d764af5B001151e163147a1(props: any) {
  return (
    <MarketingCards7f3ae3f06144437c8705D23d15300bd5 parentTag="Body274169a52d764af5B001151e163147a1" postSlug="polytopia-2025" {...props} />
  );
}

// id: "646e9aaa-b73a-4dde-8815-152e5fcb9aeb"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "274169a5-2d76-4af5-b001-151e163147a1"
export const PostSlug646e9aaaB73a4dde8815152e5fcb9aeb = "polytopia-2025";

// id: "4681c20f-3a8c-4501-8976-06b364515319"
// title: ""
// type: :text
// key: "title"
// parent_id: "0e60b66b-1654-4647-895d-34c37cc35c2b"
export const Title4681c20f3a8c4501897606b364515319 = "Polytopia 2025";

// id: "fb257389-8fbf-433c-9813-8a60a01efdd1"
// title: ""
// type: :reference
// key: "header"
// parent_id: "97c55f6f-0477-4962-a12a-c1d9a5e53c79"
export function HeaderFb2573898fbf433c98138a60a01efdd1(props: any) {
  return (
    <SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67 parentTag="HeaderFb2573898fbf433c98138a60a01efdd1" title="Polytopia 2024 Workshops" {...props} />
  );
}

// id: "97c55f6f-0477-4962-a12a-c1d9a5e53c79"
// title: "Polytopia 2024 Workshops"
// type: :html
// key: "polytopia-2024-workshops"
// parent_id: nil
export function Polytopia2024Workshops97c55f6f04774962A12aC1d9a5e53c79(props: any) {
  const PageContent = AppStore.presets["PageContent"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} Polytopia2024Workshops97c55f6f04774962A12aC1d9a5e53c79 page gf-page-layout page gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67 parentTag="HeaderFb2573898fbf433c98138a60a01efdd1" title="Polytopia 2024 Workshops" {...props} />}
      <main>
        {props["body"] || <PageContent parentTag={buildClassName("Body0a7e899928d84aabA01eE901673bbafb", parentTag)} {...props} />}
      </main>
      {props["footer"] || <DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b parentTag="Footer5254b2d5Df41481bB4b182a08b3f5a48" {...props} />}
    </div>
  );
}

// id: "0a7e8999-28d8-4aab-a01e-e901673bbafb"
// title: ""
// type: :gf_preset
// key: "body"
// parent_id: "97c55f6f-0477-4962-a12a-c1d9a5e53c79"
export function Body0a7e899928d84aabA01eE901673bbafb(props: any) {
  const PageContent = AppStore.presets["PageContent"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageContent parentTag={buildClassName("Body0a7e899928d84aabA01eE901673bbafb", parentTag)} {...props} />
  );
}

// id: "5254b2d5-df41-481b-b4b1-82a08b3f5a48"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "97c55f6f-0477-4962-a12a-c1d9a5e53c79"
export function Footer5254b2d5Df41481bB4b182a08b3f5a48(props: any) {
  return (
    <DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b parentTag="Footer5254b2d5Df41481bB4b182a08b3f5a48" {...props} />
  );
}

// id: "4257f835-9923-44af-9613-28149866b316"
// title: ""
// type: :text
// key: "title"
// parent_id: "fb257389-8fbf-433c-9813-8a60a01efdd1"
export const Title4257f835992344af961328149866b316 = "Polytopia 2024 Workshops";

// id: "efae7cbd-59e7-43fa-98d5-0ec15847a267"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHeroEfae7cbd59e743fa98d50ec15847a267(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHeroEfae7cbd59e743fa98d50ec15847a267 hero`}>
      <div className="title">
        Our mission is to change the world by promoting healthy sexuality through community and education.
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("Button1eef829cBb1a411cA9f5Edd7f48da06c", parentTag)} label="Join Us" href="/join" {...props} />}
      </div>
    </div>
  );
}

// id: "6b7b1bf7-eca3-47b0-a59c-7bee9bbbb33c"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "f359745c-8d2f-4aa1-a680-e3a0177c0c8b"
export function SocialLinks6b7b1bf7Eca347b0A59c7bee9bbbb33c(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinks6b7b1bf7Eca347b0A59c7bee9bbbb33c flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.instagram.com/sp_portland/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="currentColor" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px", fill: "currentColor"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.facebook.com/sexpositiveportland/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="currentColor" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px", fill: "currentColor"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "7f3ae3f0-6144-437c-8705-d23d15300bd5"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCards7f3ae3f06144437c8705D23d15300bd5(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards7f3ae3f06144437c8705D23d15300bd5", parentTag)} buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" postSlug="home-page-posts2" {...props} />
  );
}

const Components = {
  AdminPages317a89d25a3e470dAc4f44d32e0dd8df,
  MembersAreaDefaultA44380c32ee4432288bf30cf80753580,
  DefaultLogo5083a63329154d6d860e9f2cb86a1015,
  DefaultHeaderE9f874fa52c84847815e9999e4e21b5d,
  SecondaryPage31ccd827115449d3A514374e7dbaf851,
  PageQuote6f9bd9a08d8b4838B1933ff68986687c,
  MembersAreaHeader36838c68F266418d953c738ec447ca65,
  ResourcesPageEd43810c7d9e4c539ba0209f2e1f6903,
  MarketingBodyD8ab23221b8c40adB64e8c4e5198f3d8,
  Label4b37a8193904459d94c77defcb9b2e37,
  JoinUsPage3bfa0d57Bc0744b781903e8ac25be174,
  MemberPagesF0c2645b1e574ae9B42d805d7a193ae6,
  Href3f652b763c76472e97601f9ad404e2c8,
  SecondaryMarketingHero2fd33afcA5f84f0a84a8764657343f56,
  HomeHeader21d8d27219954d4b98dd15fd08e628de,
  Footer34fe2676Ffe14c749cc022f0ed1b5423,
  SignupPages36e4f28cC9dc4a1dAddb96229cf8f272,
  Header7836b94fCb5d4af2B92aBa79d6551435,
  SecondaryMarketingHeader8c0374dd1dab4ee79942019a93d9dc67,
  SecondaryNav634c7ad684fb4146989952bd84791a35,
  Header6c3092f4Fc6a46baBde81ebf4c1e7fcc,
  LinkedLogo5b02a935B3d14909A9843b08214e727c,
  Header05889efbD7fe4c7e8ff3B4edb83b2d01,
  TitleF31e83de900f4515Ae5e941412b2851b,
  Url90582f6f5f1041fbAbe6D58c5f96da96,
  HomePage22f0fb664f354abbA39eEad966d7c93f,
  BackgroundImageA25457c2C89e40e2A3757eebb10fa0a7,
  HeroD72ba79699e14e23B31801714ace5a80,
  TitleEfda24ffE47f4b9a9f9d7c24590c0586,
  TitleCcee19e8C49c4851A2bc5daeb6a0438c,
  Logo48fcdcceAd8f4b508b6868fec9cdc998,
  TitleB89d0495B3c64621Ad3c975e883d4629,
  PostSlug2445cd19C4784cb897deE20f42c97789,
  Header2ccd47775f6f428aAeefB6e6d894cf00,
  BodyA4b291ddEe1a4023Bfa74753ffd7623a,
  HeaderB762a06bC2414259A6293fa0b8d5b214,
  HeaderBaff33a83bc74d6dA3a4E0d363d8c8fb,
  Body53a75ced3c0843ee8ea5D6ee76dc456f,
  Class9af01396Bfc047d9Ad00Ea136aa55c56,
  NavMenuSlugB236bdcaC6dc4a7eB4b80112a9cc6039,
  PublicEventsPageCe93bc80E9a14520858e624d1b3c58e5,
  NavigationA09e4bfb14f54405A5b6B92e1e0a7561,
  GroupflowFf8e0e8c273f4b56A111A1ae103bc853,
  AltC093b8b66fb74463B27b700672bc8d98,
  TitleFabf11b0490340058c4442b21f976149,
  HeroB981dfc36b4a4826B851B89a2834761b,
  ContentSlug509d6ddeE0da4f0c8be640b269b4aba4,
  NavMenuSlug455232c9A1124a43878f781d4cb10b3c,
  DefaultFooterF359745c8d2f4aa1A680E3a0177c0c8b,
  HeaderTop0151861c20d54b8c8446E272de4fb1b9,
  Copyright0526683e548c4a1dA21121874d4a5c33,
  Footer7a6ca4f2E30d4070B0f50c3073eaa8f3,
  FooterB8592a16De384bca91a1Bee6dc9b97bf,
  PostSlugE4570dd05a92459f90dbC120f3421846,
  Class5cec5962Cefb4254B002F215681c8f98,
  LabelE50c51e258f04098B7a793e17cb34a3b,
  ShortHeader24a29dadCfce40cdAe9bE8b48f7b0092,
  ImageUrlCd472c1b6d344efa8a758850b4c01f0d,
  Navigation2c4a611077fa42558687A7066defc8c7,
  ButtonClass1a655bba266948a5Aae6514d748ed672,
  Header829ee40947b64c40Bbed374a6ad7e367,
  TitleBa6645daF1074861Ba30A5e91847de13,
  NavMenuSlug6067995b192d46ee9e8e10d72dd9efbc,
  TitleA10ce2f777a24ba49005C5de9566c279,
  Footer3b3393f18f304d4d823aCa7c88d92eb3,
  Footer348219cf75b14b17B2042e08bf5deaa0,
  Button1eef829cBb1a411cA9f5Edd7f48da06c,
  NavMenuSlugF55a32930d8e40a4Ae9eF5e1512ecbfc,
  Label5ad6d131A5804ddcAe2fB53a46ebaf81,
  LinkedLogo0e85c298Ed864eb29005D20b8ebf7e85,
  HeaderTop958b1a3134fb4e26Aa88B79ee06cdad5,
  Header5628ef5053a34380Bc4f33343354ad32,
  LinksAcaad22e76854e61883cC8c564d79fc1,
  MarketingCardsB696ecc1Cbc947fdAd2a9872a6c6b6b9,
  LabelE07775e12d3249e9A19222fdadcba5e7,
  Title659e3d8061ec4bc3A9d0E72fc5faaae5,
  PostSlug1c0a0b053b0d4c3296f9Cc157af039f7,
  Header84ebf4ff6d2d4b6a8bb1E86526d9491f,
  HeaderCeeb3eddC3aa4ceaBe28Cc29bf5084c6,
  Footer70c3280f180548cd839f8c5e48122d7c,
  HeaderTop0f9ab8c32dbe4b6fB91a5b79b9aba50e,
  Title2538bda941a44f5e906c23d45a5f83f5,
  NavMenuSlug715c8f0594cf40189e55F432e998a93e,
  Quote2a20a57fE09c4fc083e60136679cdb1c,
  HeaderD07f35454e484d08845a292523bbbf9a,
  Footer6a90d68aDbf94362915b0bcd809e37bb,
  WelcomeBackD7ff1605C63646d9B0866be12ff7651e,
  BackgroundImageEfdf42b319d54efb9f75E807ea912dbe,
  Href5b4d6fceEd0643b8Bba6B0f1bbf4fe25,
  Title8dfbb34524a249e2B1feDbf08a0be707,
  AboutLevelingPageF4d7f00fAa1446a0Befd56fd916b7809,
  HeaderBbeb56eb9e5e4d1590228e47e94d2f1d,
  Title5c2c0732Dec1447b9642287707a95f48,
  TitleCab337d66cfa48c880d86f53861213d1,
  Footer3529ab85326f480196758e39d125507d,
  MessagePages0cc80d9b091e4738A0d0B00722ae56e8,
  NavMenuSlug853e9e3bCde64728849fA7df7e65f501,
  Polytopia20244a7bde77A73a4f108cbcD18b3094fbf8,
  FooterDafab59dFf35463bB04eD4dc62ae226c,
  PostSlug7ac594c4826d499aB701Fc52ffc9a125,
  HeaderE58ea654408944e996cc2228ee808116,
  TitleDd04e8eb9d2647f79ebc882cd6f996d2,
  Body229a9882E262432dB023A01a5d623fac,
  AboutUsPage0eb43bbd02014b95809aBd20568215a2,
  DefaultHeaderTop6c5e0b8c3db74409B8fa4c5951206e8a,
  Polytopia2025601f4a4dA9844ab7BaedAf421240c568,
  Footer9bf292dfDbb6468a86ffE8e297476192,
  Header0e60b66b16544647895d34c37cc35c2b,
  Body274169a52d764af5B001151e163147a1,
  PostSlug646e9aaaB73a4dde8815152e5fcb9aeb,
  Title4681c20f3a8c4501897606b364515319,
  HeaderFb2573898fbf433c98138a60a01efdd1,
  Polytopia2024Workshops97c55f6f04774962A12aC1d9a5e53c79,
  Body0a7e899928d84aabA01eE901673bbafb,
  Footer5254b2d5Df41481bB4b182a08b3f5a48,
  Title4257f835992344af961328149866b316,
  HomePageHeroEfae7cbd59e743fa98d50ec15847a267,
  SocialLinks6b7b1bf7Eca347b0A59c7bee9bbbb33c,
  MarketingCards7f3ae3f06144437c8705D23d15300bd5
}

export default Components;